.DemoEnding {
	height: 100%;
	width: 45vw;

	display: flex;
	flex-direction: column;
	justify-content: center;

	align-items: center;
	
	.titre {
		font-size: 2em;
		text-align: center;
	}
	.ship {
		font-size: 2em;
		text-align: center;
	}

	.list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: baseline;
	}
}