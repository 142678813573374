.image_with_scrolling_text{
	border-right: 3px solid gray;
	width: 45vw;
	background-color: rgb(238, 238, 238);
	width: 45vw;
	overflow: hidden;

	display: flex;
	align-items: center;
	justify-content: baseline;
	flex-direction: column;
	height: 100%;

	transition: background-color 0.5s linear;
	.image_wrapper {
		max-height: 50vh;
		transform: translateY(-30vh);
		opacity: 0;
		margin-top: 5%;
		height: auto;
		width: 80%;

		min-height: 55%;
		aspect-ratio: 1 / 1;

		overflow: hidden;
		canvas {
			width: 100%;
			height: 100%;
			object-fit: cover;
			-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  			filter: grayscale(100%);
			transition: filter 0.7s ease-in-out, transform 0.7s ease-in-out;
		}
	}


}

.image_with_scrolling_text {
  position: relative;
}

.wrapper {
	overflow: hidden;
	white-space: nowrap;
	position: relative;
  	width: 80%;
	overflow: hidden;
}

.horizontal-scrolling-texts {
	display: inline-block;
	text-transform: uppercase;
}

.horizontal-scrolling-text {
  display: inline-block;
  white-space: nowrap;

}

.image_with_scrolling_text:hover {
	background-color: white;

	canvas {
		-webkit-filter: none; /* Safari 6.0 - 9.0 */
  		filter: none;
	}
}