.DetailsPage {

	display: flex;
	flex-direction: row;

	.behind_passive_an_panel {
		width: 10vw;
		height: 100%;
	}
	.details_wrapper {
		width: 90vw;
	}
	
	.black {
		background-color: rgb(37, 37, 37);
		color: white;
	}
}