/* ChangingImage.css */

.changing_image { 
  width: 45vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  border: 2px solid black;
  align-items: center;

  .image_size {
	position: relative;
	width: 100%; /* Adjust as needed */
	height: 100%; /* Adjust as needed */
	overflow: hidden;
  }
  
  .image_size .background {
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	height: 100%;
	opacity: 0;
	transform: scale(1.1);
	width: 100%;
	transition: opacity 2s ease-in-out, transform 2s ease; /* Adjust transition duration as needed */
  }
  
  .image_size .background.active {
	opacity: 1;
	transform: scale(1);

  }

  .fading_text {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	text-align: center;
	background-color: rgba(255, 255, 255, 0.432);
	backdrop-filter: blur(2px);

	transition: opacity 1s 0.2s ease;
	overflow: hidden;
	h1 {
		font-family: "Titillium Web", sans-serif;
		font-size: 5em;
		font-weight: 400;
	}
  }

  .fading_text:hover {
	opacity: 1;


  }
}

/* ChangingImageBackground.css */

.changing_image_background { 
  width: 45vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  border: 2px solid black;
  align-items: center;

  .wrapper {
	position: relative;
	width: 100%; /* Adjust as needed */
	height: 100%; /* Adjust as needed */
	overflow: hidden;
  }
  
  .wrapper .background {
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	height: 100%;
	opacity: 0;
	transform: scale(1.1);
	width: 100%;
	transition: opacity 2s ease-in-out, transform 2s ease; /* Adjust transition duration as needed */
  }
  
  .wrapper .background.active {
	opacity: 1;
	transform: scale(1);

  }
}

