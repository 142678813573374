.contact_form {


	width: 45vw;
	height: 100%;
	font-family: "Tittilis Web";

	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
	}

	.fading_wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		backdrop-filter: blur(5px);
		background-color: rgba(255, 255, 255, 0.432);
	}

	.submit_group {
		width: 50%;
		height: 50%;
		top: 25%;
		
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		position: absolute;
		input {
			color: black !important;
			opacity: 1;
			width: 100%;
			height: 50px;
			cursor: none !important;

			font-family: "Tittilis Web";

			border: 1px solid rgb(124, 124, 124);
			border-radius: 2px;

			font-size: 1.3em;
			padding-left: 12px;

			font-weight: 900;
			transition: border 0.4s;
		}

		input:hover {
			border: 1px solid rgb(97, 97, 97);
		}

		button {
			box-sizing: content-box;
			background-color: white;
			border: 1px solid white;

			position: relative;
			top: calc(30px);
			padding: 10px;
			color: gray;
			font-size: 1.4em;
			transition: border 1s, background-color 1s;
			border-radius: 12px;
			cursor: none;
			font-family: "Tittilis Web";
		}
		button:hover {
			border: 1px solid rgb(95, 95, 95);
			background-color: rgb(95, 95, 95);
			color: black;
		}

		h1 {
			color: black;
			font-size: 3em;
			display: inline-block;
			white-space: nowrap;
			
			position: absolute;

			top: 12%;
			font-family: "Tittilis Web", sans-serif;
			text-transform: uppercase;
			font-weight: 600;
		}

	}
	.image_wrapper {
		width: 60px;
		height: 60px;

		position: absolute;
		bottom: 25px;
		right: 25px;
		
		clip-path: circle(60%);

		cursor: none;
		img {
		
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}