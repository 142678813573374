.image_rectangle {
	width: 100%;

	display: flex;
	flex-direction: column;


	.row {
		width: 100%;
		height: 65vh;
		
		display: flex;
		flex-direction: row;
		justify-content: center;
		
		box-sizing: border-box;
		overflow: hidden;
		.image_wrapper {
			padding: 3%;

			position: relative;
			
			border: 1px solid black;
			box-sizing: border-box;

			display: flex;
			justify-content: center;
			align-items: center;
			.wrapper {
				
				background-color: whitesmoke;
				width: 100%;
				height: 100%;
				padding: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.image_size {
				position: relative;
				width: 100%;
				height: 100%;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				overflow: hidden;
			}

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}

			.inside_text {
				width: 100%;
				position: absolute;
				top: 106%;
				left: 0%;

				padding: 0;
				padding-left: 6%;
				padding-right: 6%;
				margin: 0;

				height: 25%;

				transition: top 0.7s 0.1s ease;
				backdrop-filter: grayscale(100%) brightness(40%) blur(2px);

			}

			h4 {
				font-size: 1.4em;
				color: white;
				font-weight: 200;
				word-break:	break-word;
				overflow: hidden;
				font-family: "Titillium Web", sans-serif;
			}
		}
	}

	.row.reverse {
		flex-direction: row-reverse;
	}

	.small {
		width: 35%;
	}

	.big {
		width: 65%;
	}

	.image_size:hover {
		
		.inside_text {
			top: 75%;
		}
	}
}