/* App.css */
.horizontal_scrolling_wrapper {
  display: flex;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  scroll-behavior: smooth;
}

.child {
  flex-shrink: 0;
  height: 100%;
}

.first.child {
	background-color: red;
	flex-basis: 70vw;
}

.second.child {
	background-color: lightblue;
	flex-basis: 50vw;
}

.third.child {
	background-color: grey;
	flex-basis: 20vw !important;
}
.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}
.gsap_scrolling_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  transform: none !important;

}

.gsap_row {
  display: flex;
  flex-direction: row;
  width: fit-content;
  overflow: hidden;
  z-index: 1;
}

.gsap_scrolling {
  /* Remove any initial transform if present */
  transform: none;
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  width: max-content;
}
