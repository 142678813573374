/* Animation Panel for FlyTips */


.animation_panel {
  position: fixed;
  top: 0;
  left: 0;
  transform: none !important;
  background-color: #ff8513;
  width: 10vw;
  z-index: 10; /* Ensure it's on top of the content */
  height: 100vh; /* Start covering the entire viewport height */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;

  transition: background-color 1s ease;
  
  .animation_video {
	position: relative;
	top: calc(50vh - 10vw);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 10vw;
	max-width: 10vw;
	height: 40%;
	max-height: 8vw;

	img {
		width: 100%;
		height: 100%;
	}

  }
  .text {

	--scrolling: 0;
	position: relative;
	font-size: 5em;

	top: calc(50vh + 10vw - 5em);
	h1 {
		opacity: 1;
		
		span {
			font-size: 1em;
			opacity: 1;
			scale: 0.8;
			color: whitesmoke;
		}

	}
}
.bars {
	position: absolute;


	bottom: 2%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items:center;
	height: 5vh;
	width: 5vw;
	.bar {
		opacity: 1;
		background-color: whitesmoke;
		height: 25%;
		width: 100%;
	}
}
}

.behind_animation {
  position: relative;
  z-index: 0;
  width: calc(1050px + 10vw);
  background-color: #ff8513;
}
.animation_panel.passive {
	display: flex;
	
	flex-direction: row;
	justify-content: baseline;
	align-items: baseline;
	height: 100%;
	width: 50vw;

	overflow: hidden;


	.bars {
		left: 2.5vw;
	}
	.links {
		position: absolute;
		left: 10vw;
		max-width: 40vw;
		width: 40vw;

		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		opacity: 1 !important;

		background-color: rgb(224, 224, 224);
		.link {
			padding-left: 20px;
			width: 100%;
			font-size: 2.5em;
			font-family: "Arsenal SC", sans-serif;

			display: flex;

			height: 2.5em;

			span {
				opacity: 0;
				position: relative;
				top: 0;
				right: 0.5em;
				display: block;
				width: 15px;
				margin-top: 35px;
				margin-left: 45px;
				height: 15px;
				border-top: 2px solid #000;
				border-left: 2px solid #000;
				transform: rotate(90deg) ;
			}

			span::after {
				content: "";
				display: block;
				width: 2px;
				height: 20px;
				position: relative;
				background-color: black;
				transform: rotate(-45deg) translate(7px, 5px);

			}
			
			h3 {
				margin: 0;
				padding: 0;
				display: inline-block;
				position: relative;
				font-weight: 600;
				font-family: "Titillium Web", sans-serif;


			}
			h3::after {
				content: '';
				position: absolute;
				width: 100%;
				transform: scaleX(0);
				height: 3px;
				top: 63%;
				left: 0;
				background-color: black;
				transform-origin: bottom right;
				transition: transform 0.25s ease-out;
			}
	
			h3:hover::after {
				transform: scaleX(1);
				transform-origin: bottom left;
			}
		}

		.CGU {
			font-size: 2em;
			position: absolute;
			right: 4%;
			bottom: 1%;
		}
		.socials_link {
			position: absolute;
			bottom: 3%;
			left: 2%;

			display: flex;
			flex-direction: row;


			.image_wrapper {
				cursor: none;
				width: 80px;
				height: 80px;
				margin-left: 20px;

				clip-path: circle(60%);
				opacity: 1;
				img {
					width: 100%;
					height: 100%;
					object-fit: fill;
				}
			}
		}

	}
}

.animation_panel.passive:hover {
	background-color: black;
}

.animation_panel.black {
	background-color: #000;
}