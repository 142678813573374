
.text_image_block {
	width: 100%;
	max-height: 60vh;
	height: 1000px;

	display: flex;
	flex-direction: row;
	justify-content: space-around;

	align-items: center;
	.image_wrapper {
		width: 30%;
		height: 70%;


		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.text {
		width: 40%;
		height: 80%;
		
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		h1 {
			width: 100%;
			font-size: 4em;
			letter-spacing: 0.2em;
			text-transform: uppercase;
			font-family: "Titillium Web", sans-serif;
			font-weight: 500;
			
		}
		h3 {
			line-height: 1.6em;
			font-size: 1.3em;
			font-weight: 200;
		}
	}
}

.behind_animation_height {
  position: relative;
  z-index: 0;
  height: 1050px;
}