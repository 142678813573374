.ChangingImageAndBlackText {
	box-sizing: border-box;
	.row {
		display: flex;
		flex-direction: row;
	}

	.top {
		max-height: 70vh;
		height: 900px;

		width: 90vw;
		.img_wrapper {
			padding: 24px;
			border-bottom: 1px solid black;
			height: 100%;
			width: 60%;
			position: relative;
			overflow: hidden;

			img {
				padding: 24px;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				object-fit: cover;
				opacity: 0;
				transform: scale(1.1);
				transition: opacity 2s ease-in-out, transform 2s ease;
			}
			img.active {
				opacity: 1;
				transform: scale(1);

			}
		}
		

	}
	.box {
		width: 40%;
		border-bottom: 1px solid #222;

		font-size: 1.3em;
		
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		font-family: "Titillium Web", sans-serif;

		h1 {
			position: inherit;
			padding-left: 22px;
			font-size: 4em;
			font-weight: 500;
			letter-spacing: 0.1em;
			text-transform: uppercase;
		}

		h3 {
			position: relative;
			padding-left: 12px;
			padding-right: 12px;
			font-weight: 400;
			line-height: 1.5em;
		}
	}
}