.custom_cursor {
  position: fixed;
  pointer-events: none;
  width: 20px;
  height: 20px;


  z-index: 1000; /* Ensures it is above other elements */


  .custom_cursor_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 2s ease;

  }

  .custom_cursor_wrapper.basis {
    border: 1px solid black;
    border-radius: 50%; /* Makes the cursor circular */
    pointer-events: none; /* Prevents it from blocking mouse events */
    transform: translate(-50%, -50%); /* Centers the cursor */
    backdrop-filter: invert(100%);
    display: block;
  }
  
  .custom_cursor_wrapper.eyeball {
    display: none;
    position: relative;
    transform: translate(-30px, -30px);
  }
}

body:has(.image_with_fading_text > .image_wrapper:hover, .image_with_scrolling_text >  .image_wrapper:hover) .custom_cursor {

  .custom_cursor_wrapper.basis {
    opacity: 0;
    display: none;
  }

  .custom_cursor_wrapper.eyeball {
    display: block;
    opacity: 1;
  }
}