.desktop_wrapper {
	display: flex;
	flex-direction: row;
}

.horizontal-scroll-container {
  overflow-x: scroll;
  width: fit-content;
  display: flex;

  position: absolute;
  top: 0;
  left: 0;
}
