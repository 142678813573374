.transition.first_part {

	display: flex;
	flex-direction: row;
	.part {
		height: 0%;
		width: 20%;

		background-color: #ff8513;
	}
}

.transition.second_part{
	display: flex;
	flex-direction: column;

	.part {
		height: 20%;
		width: 100%;

		background-color: #ff8513;
	}
}

.transition {
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 500;

}
