body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	cursor: none;

  height: fit-content;
}

:root {
  --element-position: 0;
  cursor: none;
}

* {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}
 
 body {
  -ms-overflow-style: none;
  scrollbar-width: none; 
} 

::-webkit-scrollbar {
    width: 0;
    height: 0;
}

::-webkit-scrollbar {
    display: none;
} 