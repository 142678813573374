.image_with_fading_text {
	width: 45vw;
	overflow: hidden;

	background-color: whitesmoke;
	display: flex;
	align-items: center;
	justify-content: baseline;
	flex-direction: column;
	height: 100%;

	transition: background-color 0.5s linear;
	.image_wrapper {
		transform: translateY(-50vh);
		margin-top: 5%;
		opacity: 0;
		height: auto;
		width: 80%;

		min-height: 55%;
		aspect-ratio: 1 / 1;

		overflow: hidden;
		canvas {
			width: 100%;
			height: 100%;
			object-fit: cover;
			-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  			filter: grayscale(100%);
			transition: filter 0.7s ease-in-out, transform 0.7s ease-in-out;
		}
	}

	.text_block {
		--element-position: 1;
		box-sizing: border-box;
		max-height: 35vh;
		margin-top: 3vh;
		height: 40%;
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		padding-bottom: 3%;
		padding-left: 10%;
		padding-right: 10%;
		font-family: "Baskerville SC", serif;
		font-weight: 600;

		opacity: 0;
		transform: translateY(0);
		line-height: 1.3em;
		h1 {
			text-transform: uppercase;
		}
		h3 {
			font-family: "Arsenal SC", sans-serif;
			font-weight: 300;
		}
	}

}

	
.image_with_fading_text:hover {
	background-color: white;
	canvas {
		filter: none;
		-webkit-filter: none;
		transform: scale(1.05);
	}
}